import { render, staticRenderFns } from "./TheSnapshotMain.vue?vue&type=template&id=5a5f9883&"
import script from "./TheSnapshotMain.vue?vue&type=script&lang=js&"
export * from "./TheSnapshotMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a5f9883')) {
      api.createRecord('5a5f9883', component.options)
    } else {
      api.reload('5a5f9883', component.options)
    }
    module.hot.accept("./TheSnapshotMain.vue?vue&type=template&id=5a5f9883&", function () {
      api.rerender('5a5f9883', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/snapshot/views/TheSnapshotMain.vue"
export default component.exports