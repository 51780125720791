<template>
    <div style="height: 100%">
        <TheSnapshotAppIframe :style="$route.name !== 'app-open' ? { 'z-index': -1, opacity: 0, position: 'fixed', top: '0px', left: '0px' } : {}" />
        <TheSnapshotFileList :style="$route.name !== 'snapshot-files' ? { 'z-index': -1, opacity: 0, position: 'fixed', top: '0px', left: '0px' } : {}" />
        <v-container
            :style="
                $route.name === 'snapshot-files' || $router.name === 'app-open' ? { 'z-index': -1, opacity: 0, position: 'fixed', top: '0px', left: '0px' } : {}
            "
            fluid
        >
            <router-view name="notifications"></router-view>
            <transition name="fadeonly" mode="out-in">
                <keep-alive :include="['snapshot-tables']">
                    <router-view></router-view>
                </keep-alive>
            </transition>
        </v-container>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// Apps
const TheSnapshotAppIframe = () => import('@/modules/snapshot/views/TheSnapshotAppIframe.vue')
const TheSnapshotFileList = () => import('@/modules/snapshot/views/TheSnapshotFileList.vue')
export default {
    components: {
        TheSnapshotAppIframe,
        TheSnapshotFileList
    },
    computed: {
        ...mapState('snapshotStore', ['snapshotQuota', 'pathArray', 'fileAreaType']),
        ...mapGetters('snapshotStore', ['currentSnapshotLockMode', 'currentFileLocalPath'])
    },
    methods: {
        checkAndWarnIfNearQuotaLimit: function() {
            if (this.snapshotQuota && parseFloat(this.snapshotQuota.bytes_pcent) > 90) {
                const message = `You have used ${parseFloat(this.snapshotQuota.bytes_pcent)}% of your storage quota (${parseFloat(
                    (this.snapshotQuota.bytes_quota / 1024 ** 3).toFixed(2)
                )} GB). This might prevent the application from functioning properly. Contact your organization manager for a larger quota.`
                this.$store.dispatch('showGlobalDialog', {
                    dialogTitle: 'File quota limits!',
                    dialogText: message,
                    dialogAction: 'dismiss'
                })
            }
        }
    },
    watch: {
        $route: {
            handler(to, from) {
                if (
                    to.params.snid &&
                    ['snapshot-files', 'snapshot-tables', 'snapshot-applications'].includes(to.name) &&
                    this.currentSnapshotLockMode === 'RESTORING'
                ) {
                    this.$store.dispatch('showGlobalDialog', {
                        dialogTitle: 'Snapshot Restoration',
                        dialogText: 'A snapshot is being restored to this instance. You can work with your data once the restoration has been completed.',
                        dialogAction: 'refreshInstanceSnapshots'
                    })
                }
                if (
                    from !== undefined &&
                    to.params.snid &&
                    from.params.snid &&
                    from.name === 'snapshot-files' &&
                    to.name === 'snapshot-files' &&
                    from.params.localPath !== to.params.localPath &&
                    to.params.localPath !== undefined &&
                    to.params.localPath !== this.currentFileLocalPath
                ) {
                    this.$store.dispatch('snapshotStore/setPathArray', to.params.localPath.split('/'))
                }
                if (
                    from !== undefined &&
                    to.params.snid &&
                    from.params.snid &&
                    from.name === 'snapshot-files' &&
                    to.name === 'snapshot-files' &&
                    from.params.localPath !== to.params.localPath &&
                    to.params.localPath === undefined &&
                    to.params.fileArea === from.params.fileArea
                ) {
                    this.$store.dispatch('snapshotStore/setPathArray', [''])
                }
                if (
                    (to.params.snid !== undefined &&
                        from !== undefined &&
                        from.params.snid !== undefined &&
                        to.params.snid.toString() !== from.params.snid.toString()) ||
                    (to.params.snid === undefined && from !== undefined && from.params.snid !== undefined)
                ) {
                    this.$store.dispatch('snapshotStore/resetSnapshotState')
                }
                if (
                    (to.params.snid !== undefined && from === undefined) ||
                    (to.params.snid !== undefined && from !== undefined && to.params.snid.toString() !== from.params.snid.toString()) ||
                    (to.params.snid !== undefined && from !== undefined && from.path === to.path)
                ) {
                    if (to.name === 'snapshot-files' && to.params.localPath !== undefined) {
                        this.$store.dispatch('snapshotStore/setPathArray', to.params.localPath.split('/'))
                    } else if (to.name === 'snapshot-files' && to.params.localPath === undefined) {
                        this.$store.dispatch('snapshotStore/setPathArray', [''])
                    }
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: to.params.snid, setFetchingStatus: true })
                    this.$store.dispatch('snapshotStore/fetchCurrentTables', to.params.snid)
                    this.$store.dispatch('snapshotStore/fetchApplications', to.params.snid)
                    this.$store.dispatch('snapshotStore/fetchSnapshotQuotaData', to.params.snid)
                }
                if (to.params.aid) {
                    this.checkAndWarnIfNearQuotaLimit()
                }
                if (from && from.params.aid && to.params.aid === undefined && to.params.snid) {
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: to.params.snid, setFetchingStatus: false })
                }
            },
            immediate: true
        },
        currentSnapshotLockMode: function(nextVal, preVal) {
            if (nextVal === null && preVal === 'RESTORING') {
                this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: this.$route.params.snid, setFetchingStatus: true })
                this.$store.dispatch('snapshotStore/fetchCurrentTables', this.$route.params.snid)
                this.$store.dispatch('snapshotStore/fetchApplications', this.$route.params.snid)
                this.$store.dispatch('hideGlobalDialog')
            }
        },
        snapshotQuota: function() {
            if (this.$route.params.aid) {
                this.checkAndWarnIfNearQuotaLimit()
            }
        },
        currentFileLocalPath: function(nextVal, preVal) {
            if (nextVal !== preVal && this.$route.params.localPath !== nextVal) {
                this.$router.push({
                    name: 'snapshot-files',
                    params: {
                        oid: this.$route.params.oid,
                        sid: this.$route.params.sid,
                        iid: this.$route.params.iid,
                        snid: this.$route.params.snid,
                        localPath: nextVal,
                        fileArea: this.fileAreaType
                    }
                })
                this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: this.$route.params.snid, setFetchingStatus: true })
            } else if (nextVal !== preVal && this.$route.params.localPath === nextVal) {
                this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: this.$route.params.snid, setFetchingStatus: true })
            }
        }
    }
}
</script>
