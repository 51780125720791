var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("TheSnapshotAppIframe", {
        style:
          _vm.$route.name !== "app-open"
            ? {
                "z-index": -1,
                opacity: 0,
                position: "fixed",
                top: "0px",
                left: "0px"
              }
            : {}
      }),
      _c("TheSnapshotFileList", {
        style:
          _vm.$route.name !== "snapshot-files"
            ? {
                "z-index": -1,
                opacity: 0,
                position: "fixed",
                top: "0px",
                left: "0px"
              }
            : {}
      }),
      _c(
        "v-container",
        {
          style:
            _vm.$route.name === "snapshot-files" ||
            _vm.$router.name === "app-open"
              ? {
                  "z-index": -1,
                  opacity: 0,
                  position: "fixed",
                  top: "0px",
                  left: "0px"
                }
              : {},
          attrs: { fluid: "" }
        },
        [
          _c("router-view", { attrs: { name: "notifications" } }),
          _c(
            "transition",
            { attrs: { name: "fadeonly", mode: "out-in" } },
            [
              _c(
                "keep-alive",
                { attrs: { include: ["snapshot-tables"] } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }